<template src="./ArrobaMedellinArrobitaNoDataProfile.html">
</template>

<script>
export default {
  name: 'arroba-medellin-arrobita-no-data-profile',
  props: {
    title: { type: String, default: "Personaliza tu avatar" },
    message: { type: String, default: "Ingresa al distrito Entretenimiento dando clic en “editar avatar” y comienza a personalizar el tuyo." }
  }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinArrobitaNoDataProfile.scss">
</style>
