import { nodeInstance } from "@/plugins/axios";

const commercialService = {};

commercialService.getAvatarGenders = () => {
  return nodeInstance.get('/api/avatar/genders');
}

commercialService.getAvatarSkinTypes = () => {
  return nodeInstance.get('/api/avatar/skin-type');
}

commercialService.getAvatarSkinTypeByUser = async (userId) => {
  const body = await nodeInstance.get(`/api/avatar/skin-type/${userId}`);
  return body.pop();
}


commercialService.getElementCategories = () => {
  return nodeInstance.get('/api/avatar/categories');
}

commercialService.getAllElements = (genderId, skinId, take, skip) => {
  return nodeInstance.get(`/api/avatar/elements/all?genderId=${genderId}&skinId=${skinId}&take=${take}&skip=${skip}`);
}

commercialService.getElementsByDefault = (genderId, skinId) => {
  return nodeInstance.get(`/api/avatar/elements/default?genderId=${genderId}&skinId=${skinId}`);
}

commercialService.getElementSubcategories = (categoryId) => {
  return nodeInstance.get(`/api/avatar/categories/${categoryId}/subcategories`);
}

commercialService.getAllElementsSubcategories = (subcategoryId) => {
  return nodeInstance.get(`/api/avatar/subcategories/${subcategoryId}/elements`);
}

commercialService.getAllBoughtElements = (subcategoryId) => {
  console.log("subcategoryId", subcategoryId)
  return nodeInstance.get(`/api/user/avatar/subcategories/${subcategoryId}/elements`);
}

commercialService.getSelectedAccessoriesAvatar = (userId) => {
  if (userId) return nodeInstance.get(`/api/user/avatar?userId=${userId}`);
  return nodeInstance.get(`/api/user/avatar`);
}

commercialService.setAvatarGender = (avatarGenderId) => {
  return nodeInstance.post(`/api/avatar/genders/${avatarGenderId}`);
}

commercialService.setAvatarSkinType = (avatarSkinTypeId) => {
  return nodeInstance.post(`/api/avatar/skin-type/${avatarSkinTypeId}`);
}

commercialService.buyElement = (elementId) => {
  return nodeInstance.post(`/api/avatar/elements/${elementId}/buy`);
}

commercialService.selectBoughtElement = (elementId) => {
  return nodeInstance.put(`/api/user/avatar/element/${elementId}/select`);
}

commercialService.unselectBoughtElement = (elementId) => {
  return nodeInstance.patch(`/api/user/avatar/element/${elementId}/unselect`);
}

commercialService.getScoreAndTimeGame = (game) => {
  return nodeInstance.get(`/api/softSkillsGame/best?game=${game}`);
}

export default commercialService;
