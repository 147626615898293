<template src="./ItemsToBuy.html"></template>

<script>
import commercialService from "@/services/Commercial/Commercial";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { songs } from "@/utils/constants";

export default {
  name: "ItemsToBuy",
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: "avatar",
    },
  },
  data() {
    return {
      songs,
      isCardModalActive: false,
      isCardModalActiveSuccess: false,
      isCardModalActiveNotice: false,
    };
  },
  computed: {
    ...mapGetters({
      avatarElements: "avatarItems",
      notice: "getNotice",
    }),
  },
  methods: {
    ...mapMutations({
      setAvatarLook: "setAvatarLook",
      fetchNotice: "fetchNotice",
    }),
    ...mapActions({
      setUserInfo: "fetchUserInfo",
    }),
    setNotice(notice) {
      this.$store.dispatch("fetchNotice", notice);
      this.isCardModalActiveNotice = false;
    },
    async selectItem(element) {
      this.$store.dispatch("setAvatarLook", element);
      this.$emit("select");
      if (!this.notice) {
        this.isCardModalActiveNotice = true;
      } else {
        this.isCardModalActiveNotice = false;
      }
      if (this.section == "bought") {
        await commercialService.selectBoughtElement(element.id);
      }
    },
    async applyItem(itemId) {
      if (this.section === "bought") {
        try {
          await commercialService.unselectBoughtElement(itemId);
          this.$emit("unselect");
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.response?.data?.message ?? "Se presentó un error",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      } else if (this.section === "avatar") {
        this.isCardModalActive = true;
      }
    },
    async buyItem(itemId) {
      if (this.section === "avatar") {
        try {
          await commercialService.buyElement(itemId);
          await this.setUserInfo();
          this.isCardModalActive = false;
          this.isCardModalActiveSuccess = true;
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.response?.data?.message ?? "Se presentó un error",
            position: "is-bottom",
            type: "is-danger",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./ItemsToBuy.scss">
</style>
