<template src="./Avatar.html"></template>

<script>
import commercialService from "@/services/Commercial/Commercial";
import { mapGetters } from "vuex";

export default {
  name: "avatar",
  props: ["user"],
  data() {
    return {
      bodyUser: "",
      selectedItems: []
    };
  },
  computed: {
    ...mapGetters({
      avatarElements: "avatarItems"
    })
  },
  mounted() {
    this.getBodyUser(this.$props.user.id);
    this.getSelectedAccessoriesAvatar(this.$props.user.id);
  },
  methods: {
    async getBodyUser(userId) {
      try {
        this.bodyUser = await commercialService.getAvatarSkinTypeByUser(userId);
      } catch (e) {
        console.error("error", e);
      }
    },
    async getSelectedAccessoriesAvatar(id) {
      try {
        //Initial empty array
        const avatarElements = [];

        const [selectedAccessories, defaultElements] = await Promise.all([
          commercialService.getSelectedAccessoriesAvatar(id),
          commercialService.getElementsByDefault(
            this.$props.user.avatarGender.id,
            this.$props.user.avatarSkin.id
          )
        ]);

        //Adding default elements
        defaultElements.map(element => {
          avatarElements.push(element);
        });
          
        //Adding selected elements
        selectedAccessories.map(element => {
          const {
            avatarElement
          } = element;
          const elementData = {
            ...avatarElement,
            avatarSubcategory: avatarElement.avatarSubcategory
          };
          avatarElements.push(elementData);
        });

        //send default and selected elements to store
        this.$store.dispatch("setAvatarElements", avatarElements);
      } catch (e) {
        console.error("error", e);
      }
    }
  }
};
</script>

<style lang="scss" scoped src="./Avatar.scss"></style>
