<template src="./ComercialAvatarStore.html"></template>

<script>
import Avatar from "@/components/Avatar/Avatar";
import BuyPopup from "./BuyPopup/BuyPopup";
import FirstEntryModal from "./FirstEntryModal/FirstEntryModal";
import FirsEntrySkinModal from "./FirsEntrySkinModal/FirsEntrySkinModal";
import ItemsToBuy from "@/components/ItemsToBuy/ItemsToBuy";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import commercialService from "@/services/Commercial/Commercial";
import { mapGetters } from "vuex";
import tutorial from "@/mixins/tutorial";

export default {
  name: "ComercialAvatarStore",
  mixins: [tutorial],
  components: {
    BuyPopup,
    Avatar,
    ItemsToBuy,
    FirstEntryModal,
    FirsEntrySkinModal,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      bodyUser: "",
      vButtons: "",
      buyObject: false,
      openModal: false,
      openFirstEntryModal: false,
      openFirstEntrySkinModal: false,
      avatarGenders: [],
      avatarSkinTypes: [],
      elementCategories: [],
      elementSubcategories: [],
      allElementsSubcategory: [],
      allElements: [],
      selectedAccessories: [],
      selectedItem: null,
      take: 0,
      takeMore: 12,
      skip: 12,
      subcategoryId: null,
      noData: false,
    };
  },
  props: {
    section: {
      type: String,
      default: "avatar",
    },
  },
  methods: {
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    async getBodyUser(userId) {
      try {
        this.bodyUser = await commercialService.getAvatarSkinTypeByUser(userId);
        if (!this.bodyUser) this.openFirstEntrySkinModal = true;
      } catch (e) {
        console.error("error", e);
      }
    },
    getElementSubcategory(category) {
      this.vButtons = category.name;
      this.getElementSubcategories(category.id);
      this.noData = true;
    },
    closeModal() {
      this.buyObject = false;
    },
    closeFirstEntryModal() {
      this.openFirstEntryModal = false;
      if (!this.userInfo.avatarSkin) this.openFirstEntrySkinModal = true;
    },
    closeFirstEntrySkinModal() {
      this.openFirstEntrySkinModal = false;
    },
    async getElementsCategories() {
      try {
        this.allElementsSubcategory = [];
        this.elementCategories = await commercialService.getElementCategories();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getAllElements() {
      try {
        this.allElements = await commercialService.getAllElements(
          this.userInfo.avatarGender.id,
          this.userInfo.avatarSkin.id,
          this.skip,
          this.take
        );
      } catch (e) {
        console.error("error", e);
      }
    },
    async getMoreElements() {
      const elements = await commercialService.getAllElements(
        this.userInfo.avatarGender.id,
        this.userInfo.avatarSkin.id,
        this.skip,
        this.takeMore
      );
      this.allElements.push(...elements);
      if (elements.length > 0) this.takeMore += this.skip;
    },
    async getElementSubcategories(categoryId) {
      try {
        this.elementSubcategories =
          await commercialService.getElementSubcategories(categoryId);
      } catch (e) {
        console.error("error", e);
      }
    },
    async getAllBoughtElements(subcategoryId) {
      try {
        const boughtElements = await commercialService.getAllBoughtElements(
          subcategoryId
        );
        this.allElementsSubcategory = boughtElements.reduce((arr, item) => {
          const { avatarElement } = item;
          if (avatarElement) {
            arr.push({
              ...avatarElement,
              avatarSubcategory: avatarElement.avatarSubcategory,
            });
          } else {
            arr.push(item);
          }
          return arr;
        }, []);
      } catch (e) {
        console.error("error", e);
      }
    },
    async getAllElementsSubcategory(subcategoryId) {
      try {
        this.subcategoryId = subcategoryId;
        if (this.section === "bought")
          await this.getAllBoughtElements(subcategoryId);
        else {
          this.allElementsSubcategory =
            await commercialService.getAllElementsSubcategories(
              this.subcategoryId,
            );
        }
        this.noData = false;
      } catch (e) {
        console.error("error", e);
      }
    },
    async getSelectedAccessoriesAvatar() {
      try {
        await commercialService.getSelectedAccessoriesAvatar();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getMoreElementsSubcategory() {
      try {
        const elementsSubcategories =
          await commercialService.getAllElementsSubcategories(
            this.subcategoryId,
          );
        this.allElementsSubcategory.push(...elementsSubcategories);
      } catch (e) {
        console.error("error", e);
      }
    },
    viewTutorial() {
      this.dataTutorial("comercialStore");
    },
  },
  watch: {
    section() {
      this.allElementsSubcategory = [];
    },
  },
  created() {
    this.getElementsCategories();
  },
  async beforeMount() {
    this.getAllElements();
  },
  async mounted() {
    try {
      if (!this.userInfo.avatarGender || !this.userInfo.avatarSkin)
        this.openFirstEntryModal = true;
      await Promise.all([
        this.getBodyUser(this.userInfo.id),
        this.getSelectedAccessoriesAvatar(this.userInfo.id),
      ]);
    } catch (e) {
      console.error("error", e);
    }
    this.viewTutorial();
  },
  computed: {
    ...mapGetters({
      userInfo: "getUser",
    }),
  },
};
</script>

<style lang="scss" scoped src="./ComercialAvatarStore.scss"></style>
