import { nodeInstance } from "@/plugins/axios";

const tutorialService = {};

tutorialService.getTutorial = view => {
    return nodeInstance.get(`/api/tutorial/sumview/${view}`);
};

tutorialService.getTutorialByUser = () => {
    return nodeInstance.get('/api/tutorial/user');
};

export default tutorialService;