import { mapGetters } from "vuex";
import tutorialService from "@/services/Tutorial";
import { showTutorial } from "@/utils/constants";
import { roleName } from "@/utils/constants";

const tutorial = {
    data() {
        return {
            userRole: null,
            roles: roleName,
            myOptions: {
                highlight: true,
                enabledButtons: {
                    buttonNext: true,
                    buttonSkip: false,
                    buttonPrevious: false,
                },
                labels: {
                    buttonNext: "Continuar",
                    buttonStop: "Finalizar",
                },
            },
            stepsProfile: [
                {
                    target: "#v-step-profile1",
                    header: {
                        title: "Sección ver certificados",
                    },
                    content: `En esta sección podrás ver y descargar todos los certificados obtenidos en la ciudadela`,
                },
                {
                    target: "#v-step-profile2",
                    header: {
                        title: "Sección Publicidad",
                    },
                    content: `En esta sección podrás ver la publicidad relacionada con eventos o convocatorias de empleo publicadas.`,
                },
                {
                    target: "#v-step-teacherQualify",
                    header: {
                        title: "Sección calificar y recomendar docente",
                    },
                    content: `En esta sección podrás calificar y recomendar a un Docente.`,
                },
            ],
            stepsEditProfile: [
                {
                    target: "#v-step-editProfile1",
                    header: {
                        title: "Sección Cargar Hoja de vida",
                    },
                    content: `En esta sección puedes cargar tu hoja de vida para mejorar tus oportunidades en el Distrito Bolsa de Trabajo`,
                },
                {
                    target: "#v-step-editProfile2",
                    header: {
                        title: "Sección Redes sociales",
                    },
                    content: `Ingresa los links de tus Redes sociales y tus perfiles para mejorar tu experiencia en la Ciudadela Universitaria DIgital`,
                },
            ],
            stepsAcademic: [
                {
                    target: ".v-step-academic1",
                    header: {
                        title: "Sección ver curso",
                    },
                    content: `En esta sección podrás ver la información relacionada sobre el curso.`,
                },
            ],
            stepsNetworking: [
                {
                    target: ".v-step-networking1",
                    header: {
                        title: "Sección Crear sala",
                    },
                    content: `Crea una nueva sala de estudio en el Distrito Networking clickeando el botón “Crear una nueva sala`,
                },
            ],
            stepsJob: [
                {
                    target: ".v-step-job1",
                    header: {
                        title: "Sección Ver hoja de vida",
                    },
                    content: `En esta sección podrás ver o crear tu hoja de vida`,
                },
            ],
            stepsModalJob: [
                {
                    target: ".v-step-modalJob1",
                    header: {
                        title: "Crear hoja de vida",
                    },
                    content: `Crea tu hoja vida en un formato sencillo o en el formato único legal`,
                },
                {
                    target: ".v-step-modalJob2",
                    header: {
                        title: "Subir hoja de vida",
                    },
                    content: `Puedes guardar una hoja de vida en un formato diferente al de la Ciudadela.`,
                },
            ],
            stepsComercialStore: [
                {
                    target: ".v-step-comercial1",
                    header: {
                        title: "Sección Buscar Elementos",
                    },
                    content: `Utiliza la navegación superior y selecciona las categorías y subcategorías para buscar los ítems que quieres comprar`,
                },
                {
                    target: ".v-step-comercial2",
                    header: {
                        title: "Sección Comprar Elemento",
                    },
                    content: `Al clickear en el botón del valor del item que quieras elegir, este se aplica sobre tu avatar y luego debes comprarlo`,
                },
                {
                    target: ".v-step-comercial3",
                    header: {
                        title: "Sección Comprar",
                    },
                    content: `Cuando el ítem esté aplicado sobre tu avatar, puedes comprarlo clickeando el botón “comprar”`,
                },
            ],
            stepsEvents: [
                {
                    target: ".v-step-events1",
                    header: {
                        title: "Sección Eventos de tu Interés",
                    },
                    content: `En esta sección encontrarás los eventos que quizá te interesen en la Ciudadela`,
                },
                {
                    target: ".v-step-events2",
                    header: {
                        title: "Sección Últimos Eventos",
                    },
                    content: `Aquí podrás ver los eventos a los que ya has asistido anteriormente`,
                },
                {
                    target: ".v-step-events3",
                    header: {
                        title: "Sección Eventos de Hoy",
                    },
                    content: `Encuentra en esta sección los eventos del día de hoy en la Ciudadela`,
                },
            ],
            stepsIdi: [
                {
                    target: ".v-step-idi1",
                    header: {
                        title: "Sección selector de la Institución",
                    },
                    content: `En este selector podrás elegir la institución que deseas visualizar todo lo relacionado con I+D+i.`,
                },
                {
                    target: ".v-step-idi2",
                    header: {
                        title: "Sección Mis convocatorias",
                    },
                    content: `Aquí podrás ver las convocatorias en las que estás participando con tu grupo de investigación.`,
                },
            ],
            stepsDetailAnnouncement: [
                {
                    target: ".v-step-detailAnnouncement1",
                    header: {
                        title: "Sección Mis grupos participantes",
                    },
                    content: `En esta sección podrás ver tus grupos participantes y el estado de la postulación.`,
                },
            ],
            stepsEntertainment: [
                {
                    target: ".v-step-entertainment1",
                    header: {
                        title: "Personaliza tu Avatar",
                    },
                    content: `En esta sección podrás canjear tus @créditos por accesorias para personalizar tu avatar.`,
                },
                {
                    target: ".v-step-entertainment2",
                    header: {
                        title: "Sección Juegos",
                    },
                    content: `En esta sección podrás ingresar a jugar y obtener @créditos.`,
                },
            ],
            stepsChangeHome2d: [
                {
                    target: ".v-step-changeHome2d1",
                    header: {
                        title: "Sección visualizar mapa 2D o 3D",
                    },
                    content: `En esta sección podrás cambiar del mapa 2D a 3D si este te presenta alguna dificultad.`,
                },
            ],
            stepsChangeHome3d: [
                {
                    target: ".v-step-changeHome3d1",
                    header: {
                        title: "Sección visualizar mapa 2D o 3D",
                    },
                    content: `En esta sección podrás cambiar del mapa 2D a 3D si este te presenta alguna dificultad.`,
                },
            ],
            stepsResearchGroup: [
                {
                    target: ".v-step-researchGroup1",
                    header: {
                        title: "Sección Material de apoyo",
                    },
                    content: `Encuentra los distintos documentos subidos por el docente investigador.`,
                },
                {
                    target: ".v-step-researchGroup2",
                    header: {
                        title: "Sección Ingresar a la sala meet",
                    },
                    content: `Accede a la sala privada del grupo de investigación.`,
                },
            ],
        }
    },
    methods: {
        async dataTutorial(view) {
            this.userRole = this.user.role.name;
            if (this.userRole === roleName.STUDENT || this.userRole === roleName.INVITED) {
                this.$store.dispatch("initTutorial");
                if (this.activateTutorial) {
                    await tutorialService.getTutorial(view);
                    const infoTutorial = await tutorialService.getTutorialByUser();
                    const district = infoTutorial.find(tutorial => tutorial.view === view)
                    if (district.count <= showTutorial) {
                        switch (district.view) {
                            case "profile":
                                this.$tours["myTourProfile"].start();
                                break;
                            case "editProfile":
                                this.$tours["myTourEditProfile"].start();
                                break;
                            case "academic":
                                this.$tours["myTourAcademic"].start();
                                break;
                            case "networking":
                                this.$tours["myTourNetworking"].start();
                                break;
                            case "job":
                                this.$tours["myTourJob"].start();
                                break;
                            case "comercialStore":
                                this.$tours["myTourComercialStore"].start();
                                break;
                            case "events":
                                this.$tours["myTourEvents"].start();
                                break;
                            case "idi":
                                this.$tours["myTourIdi"].start();
                                break;
                            case "detailAnnouncement":
                                this.$tours["myTourDetailAnnouncement"].start();
                                break;
                            case "entertainment":
                                this.$tours["myTourEntertainment"].start();
                                break;
                            case "home2d":
                                this.$tours["myTourChangeHome2d"].start();
                                break;
                            case "home3d":
                                this.$tours["myTourChangeHome3d"].start();
                                break;
                            case "researchGroup":
                                this.$tours["myTourResearchGroup"].start();
                                break;
                            case "modalJob":
                                this.$tours["myTourModalJob"].start();
                                break;
                        }
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters({
            user: "getUser",
            activateTutorial: "getStatusTutorial",
        }),
    },
}

export default tutorial;