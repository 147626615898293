<template src="./FirsEntrySkinModal.html">

</template>

<script>
import commercialService from "@/services/Commercial/Commercial";

export default {
  name: 'firs-entry-skin-modal',
  props: {
    openFirstEntrySkinModal: { type: Boolean, default: false }
  },
  data(){
    return{
      avatarSkinTypes: []
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeFirstEntrySkinModal", false);
    },
    async getAvatarSkinTypes() {
      this.avatarSkinTypes = await commercialService.getAvatarSkinTypes()
    },
    async setAvatarSkinTypes(id) {
      try {
        const response = await commercialService.setAvatarSkinType(id);
        const {avatarSkin, avatarGender} = response
        this.$store.dispatch("fetchUserInfo", {avatarSkin, avatarGender});
        this.closeModal();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 1000,
          message: e.response.data.message,
          position: 'is-bottom',
          type: 'is-danger'
        })
        setTimeout(() => {
          this.closeModal();
        }, 1000)
      }
    }
  },
  mounted() {
    this.getAvatarSkinTypes()
  }
}
</script>

<style lang="scss" scoped src="./FirsEntrySkinModal.scss">

</style>
