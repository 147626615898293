<template src="./ComercialStore.html"></template>

<script>
import Avatar from "@/components/Avatar/Avatar";
import ComercialAvatarStore from "@/components/ComercialAvatarStore/ComercialAvatarStore";
import ArrobaMedellinArrobitaNoDataProfile from "@/components/ArrobaMedellinArrobitaNoDataProfile/ArrobaMedellinArrobitaNoDataProfile";
import { mapGetters } from "vuex";
import { songs } from "@/utils/constants";

export default {
  name: "CommercialStore",
  components: {
    Avatar,
    ComercialAvatarStore,
    ArrobaMedellinArrobitaNoDataProfile,
  },
  data() {
    return {
      cSection: "avatar",
      songs,
    };
  },
  created() {
    this.cSection = this.$route.params.section
      ? this.$route.params.section
      : "avatar";
  },
  mounted() {
    this.songs.COMMERCIAL.play();
  },
  methods: {
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    changeSection(section) {
      this.cSection = section;
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      activateTutorial: "getStatusTutorial",
    }),
    commertialSection() {
      return this.cSection;
    },
  },
};
</script>

<style lang="scss" scoped src="./ComercialStore.scss"></style>
