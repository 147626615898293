<template src="./BuyPopup.html"></template>

<script>

export default {
  name: "commercia-popup",
  data() {
    return {
      vButtons: "",
    };
  },
  props: {
        openModal: { type: Boolean, defaul: false }
    },
  methods: {
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    closePopup(){
        this.$emit("closeModal", false);
    }
  },
};
</script>

<style lang="scss" scoped src="./BuyPopup.scss"></style>