<template src="./FirstEntryModal.html">
</template>

<script>
import commercialService from "@/services/Commercial/Commercial";
import {mapActions} from "vuex";

export default {
  name: 'first-entry-modal',
  props: {
    openFirstEntryModal: { type: Boolean, default: false }
  },
  data(){
    return{
      avatarGenders: [],
      avatarGender: null
    }
  },
  methods: {
    ...mapActions({
      setUserInfo: 'fetchUserInfo'
    }),
    closeModal() {
      this.$emit("closeFirstEntryModal", false);
    },
    async getAvatarGender() {
      try {
        this.avatarGenders = await commercialService.getAvatarGenders();
      } catch (e) {
        console.error('error', e);
      }
    },
    async setAvatarGender(id) {
      try {
        const response = await commercialService.setAvatarGender(id);
        await this.setUserInfo(response);
        this.closeModal();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 1000,
          message: e.response.data.message,
          position: 'is-bottom',
          type: 'is-danger'
        })
        setTimeout(() => {
          this.closeModal();
        }, 1000)
      }
    }
  },
  mounted() {
    this.getAvatarGender();
  }
}
</script>

<style lang="scss" scoped src="./FirstEntryModal.scss">
</style>
